import { axiosInstance } from "../instance"

export const  userApi = {

  async getTokenTwa ( params ) {

    const res = await axiosInstance.post('/twa/token', {

      data: window.Telegram.WebApp.initData,

      // env: 'dev',
      // data: 'query_id=AAGMZtNfAAAAAIxm01__LYNC&user=%7B%22id%22%3A1607689868%2C%22first_name%22%3A%22%D0%90%D0%BD%D0%B4%D1%80%D0%B5%D0%B9%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22REDWI_a%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1715820150&hash=7ec71e87562d4b93abe039ccf5fb51eece86dd6dc88ecacb1ff59aab89e1d59a'

    })

    window.localStorage.setItem('token', res.data.token)

    return res.data.token

  },

  async getDealHistory( params ) {

    return ( await axiosInstance.post('/v1/user/deal/list', params )).data

  },

  async getActiveUser() {

    return ( await axiosInstance.post('/v1/user/get/data') ).data

  }

}
