
import { ReactComponent as Sound } from '@/react/components/icons/sound.svg';
import { ReactComponent as ArrowRight } from '@/react/components/icons/ArrowRightLong.svg';
import s from '../chat.module.scss'
import { useMutation } from '@tanstack/react-query';
import { chatApi } from '../../../../api/chat';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Controls = ({

  sending_currency_value,
  sending_currency_short,
  sending_currency,
  getting_currency_value,
  getting_currency_short,
  getting_currency,
  soundControl = () => { },
  isMuted = true,

}) => {

  const [ params ] = useSearchParams()
  const chatId = params.get('id')

  const [ isMuteState, setIsMuteState ] = useState( false )

  useEffect(() => {

    setIsMuteState( isMuted )

  }, [ isMuted ])

  const soundMutation = useMutation({

    mutationFn: () => chatApi.muteChat({ value: !isMuteState, chatId }),
    onSuccess: () => setIsMuteState( prev => !prev )

  })

  return (


    <div className={`${s.chat__controls__memo} flex between items-center absolute`}>

      <div className="flex items-center">

        <p className={`${s.chat__controls__memo__text} text-16-m`}>

          {sending_currency_value}&nbsp;
          {sending_currency_short}&nbsp;
          {sending_currency}

        </p>

        <ArrowRight className={s.chat__controls__memo__arrow} />

        <p className={`${s.chat__controls__memo__text} text-16-m`}>

          {getting_currency_value}&nbsp;
          {getting_currency_short}&nbsp;
          {getting_currency}

        </p>

      </div>

      <div className={`${ s.sound } ${ isMuteState && s.mute }`}>

        <Sound

          onClick={() => soundMutation.mutate()}
          className={`${ s.chat__controls__memo__sound } pointer`}

        />

      </div>

    </div>

  );

};

export default Controls

