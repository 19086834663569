import React, { useState } from 'react';

import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowDownGhost } from '../../icons/ArrowDownGhost.svg';

import s from './infodesk.module.scss';

/*отправка & получение валют*/

const InfoDesk = ({
  
  graytext = false,
  title = "",
  children,
  className

}) => {

  const [ isOpened, setIsOpened ] = useState( false );

  function toggle() { setIsOpened( !isOpened ) };

  return (

      <section className = {`${ s.infodesk } ${ isOpened && s['infodesk--opened'] }`}>

        <div
        
          onClick = { () => toggle() }
          className = "flex items-center between pointer"
          
        >

          <div className = "flex items-center">

            <p className = {`${ s.infodesk__title } ${ graytext && s.infodesk__title__ghost } text-14-m`}>{ title }</p>
 
          </div> 

          { graytext ?  <ArrowDownGhost className = { s.infodesk__arrow }/> :  <ArrowDown className = { s.infodesk__arrow }/> }

        </div>  

        <div className={ className } >

          { children }

        </div>

      </section>

  )

}

export default InfoDesk;