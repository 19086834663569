import React, { useState } from 'react';
import Carcas from '../../components/containers/carcas';
import s from './bid.module.scss';
import InfoDesk from '../../components/containers/infodesk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { chatApi } from '../../../api/chat';
import Button from '../../components/ui/button';
import { userApi } from '../../../api/user';
import Order from '../order';
import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import { offerApi } from '../../../api/offer';


const Bid = () => {

  const navigate = useNavigate();

  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const activeId = searchParams.get('id')
  const [offerRequisites, setOfferRequisites] = useState(null)

  const { data: offersList, isLoading: offersListLoading } = useQuery({

    queryKey: ['offersList'],

  })

  const { data: userData, isLoading: userDataLoading, isError } = useQuery({

    queryKey: ['userData'],
    queryFn: () => userApi.getActiveUser(),

  })

  if ( !offersList ) navigate('/offers')

  const activeItem = offersList?.data?.list?.find(el => el.id === +activeId)

  const { data: Requisites } = useQuery({

    queryFn: () => offerApi.getRequisites({

      initiator_id: userData.data.id,
      offer_id: activeItem.id,
      owner_id: activeItem.user.id,

    }),

  })

  console.log(Requisites);

  const createChatMutation = useMutation({

    mutationFn: ( reqId ) => chatApi.createChat({

      currency_from: activeItem.currency_from,
      currency_to: activeItem.currency_to,
      first_user_id: activeItem.user.id,
      offer_id: activeItem.id,
      second_user_id: userData?.data?.id,
      sum: activeItem.value_from,
      sum_from: activeItem.value_from,
      sum_to: activeItem.value_to,
      type: userData?.data?.id === activeItem.user.id ? "buy" : "sale",
      deal_requisite_id: reqId

    }),

    onSuccess: (res) => {

      if (!res.status) {

        Store.addNotification({

          title: res.code,
          message: res.message,
          type: "danger",
          insert: "top",
          container: "top-full",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }

        })

        return

      }

      navigate(`/chat?id=${res.data.chat_id}`)

    }

  })

  const setRequisitesMutation = useMutation({

    mutationFn: async ({ identifier, requisites }) => chatApi.setRequisites({

        initiator_id: userData?.data?.id,
        offer_id: activeItem.id,
        owner_id: activeItem.user.id,
        identifier,
        requisites,

      })

    ,

    onSuccess: (res) => { createChatMutation.mutate(res.data.data.id); }

  })


  const orderAction = ({ requisites, identifier }) => {

    setRequisitesMutation.mutate({ identifier, requisites })

  }

  const enterAction = () => {

    console.log('create chat');
    console.log(Requisites);
    if ( !Requisites ) return

    let requisitesSettings = {}


    if ( Requisites?.data?.owner_requisites?.requisites ) {

      requisitesSettings = {

        ...requisitesSettings,
        requisites: Requisites.data.owner_requisites.requisites,

      }


    }

    if (Requisites?.data?.owner_requisites?.identifier) {

      requisitesSettings = {

        ...requisitesSettings,
        identifier: Requisites.data.owner_requisites.identifier,

      }

    }

    if (Object.keys(requisitesSettings).length) { setOfferRequisites(requisitesSettings) } else {
      createChatMutation.mutate()
    }

  }

  if (userDataLoading || offersListLoading || !userData) return 'loading...'
  if (isError) return navigate('/')

  return (

    <>

      {!offerRequisites ?

        <Carcas withBack lightGrayTheme>

          <p className={`${s.title} text-32-m`}>{t('bid.title')}</p>

          <section className={s.container}>

            <div className={s.bid}>

              <div className="flex between">

                <p className={`${s.bid__name} text-18-m`}>{activeItem?.user?.name}</p>

                <p className={`${s.bid__deals} ${s.bid__deals__graytext} text-16-m`}>

                  {activeItem?.deals_count + ' ' + t('bid.offers')}

                </p>

              </div>

              <div className="flex">

                <div className={s.bid__left}>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__thin} text-16-m`}>

                    {t('bid.reviews.positive')}

                  </p>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__thin} text-16-m`}>

                    {t('bid.reviews.negative')}

                  </p>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__thin} text-16-m`}>

                    {t('bid.reviews.neutral')}

                  </p>

                </div>

                <div>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext__thin} ${s.bid__deals__graytext__positive} text-16-m`}>

                    {activeItem?.review_status?.positive}

                  </p>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext__thin} ${s.bid__deals__graytext__negative} text-16-m`}>

                    {activeItem?.review_status?.negative}

                  </p>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__thin} text-16-m`}>

                    {activeItem?.review_status?.neutral}

                  </p>

                </div>

              </div>

            </div>

            <div className={s.bid}>

              <div className="flex">

                <div className={s.bid__left__short}>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__thin} text-16-m`}>

                    {t('bid.send')}

                  </p>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__thin} text-16-m`}>

                    {t('bid.get')}<br /><br />

                  </p>

                  {activeItem?.payment_types?.payment_type_to?.name &&

                    <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__thin} text-16-m`}>

                      {t('ui.pay_type')}

                    </p>

                  }

                </div>

                <div className='flex flex-col' >

                  <p className={`${s.bid__deals} text-16-m`}>

                    {activeItem?.value_from}&nbsp;
                    {activeItem?.currency_from}&nbsp;

                  </p>

                  <p className={`${s.bid__deals} text-16-m`}>

                    {activeItem?.value_to}&nbsp;
                    {activeItem?.currency_to}&nbsp;

                    <br />

                  </p>

                  <p className={`${s.bid__deals} ${s.bid__deals__graytext} ${s.bid__deals__graytext__purple} text-16-m`}>

                    {activeItem?.payment_types?.payment_type_to?.name}

                  </p>

                </div>

              </div>

            </div>

            {activeItem?.info && <div className={s.bid}>

              <p className={`${s.bid__deals_regular} text-14-m`}>

                {activeItem?.info}

              </p>

            </div>}

            {activeItem?.exchanger_rule

              ? <InfoDesk graytext title={t('bid.exchangeRules')}>

                {activeItem?.exchanger_rule.replace('<p>', '').replace('</p>', '')}

              </InfoDesk> : ''

            }

            <Button

              name = { t('ui.exchange') }
              className = { s.button }
              isDisabled = { createChatMutation.isPending }
              action = { enterAction }

            />

            <p className = {`${s.note} ${s.bid__deals__graytext} text-14-m`}>

              {t('bid.dealsText')}

            </p>

          </section>

        </Carcas>

        : <Order isPending={createChatMutation.isPending || setRequisitesMutation.isPending} requisites={offerRequisites} activeItem={activeItem} action={orderAction} />

      }

    </>

  )

}



export default Bid;