import React, { Fragment } from 'react';
import s from './carcas.module.scss';
import { ReactComponent as ArrowBack } from '../../icons/ArrowBack.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/*скелет*/

const Carcas = ({
  
  children,
  chatMode = false,
  chatData = {},
  withBack = false,
  actionBack = () => window.history.back(),
  withCancel = false,
  actionCancel = () => window.history.back(),
  grayTheme = false,
  lightGrayTheme = false,
  companion = null,
  avatars = null,

}) => {

  const { t, i18n } = useTranslation()

  return (

    <main className = {`${ s.carcas }`}>

      <header
      
        className = {`${ s.header } ${ chatMode && s.header__chat }
        ${ grayTheme && s.header__gray } ${ lightGrayTheme && s.header__gray__light }
        flex column items-center relative`}
        
      >

        <NavLink to={'/'}>
          
          <h1 className={`${s.header__title} text-17-m`}>

            {chatMode ? companion && companion.user.name : "TWIX.IM"}

          </h1>
          
        </NavLink>

        <p className = {`${ s.header__subtitle } text-13-m`}>
          
          {chatMode ? "#" + chatData?.data?.deal?.id : "bot"}
          
        </p>

        { withBack &&
        
          <div className = "flex">

            <div


              onClick = { () => actionBack() }
              className = {`${ s.header__back } flex text-17-m absolute pointer`}
          
            >
            
              <ArrowBack className = { s.header__back__arrow }/>

              {t('ui.back')}
              
            </div>

          </div>

          
        }

        { withCancel &&

          <div
          
            onClick = { () => actionCancel() }
            className = {`${ s.header__cancel } text-17-m absolute pointer`}
            
          >
            
            Cancel
            
          </div>
          
        }

        { chatMode
        
          ?
     
          <div className={`${s.header__menu__avatars} absolute`} >

            <div className={s.header__menu__avatar}>

              { avatars?.[0]?.url  ? <img src = { avatars?.[0]?.url } alt="avatar" />  : <span>🐱‍🚀</span> } 

            </div>

            { avatars?.length > 1 && <div className={s.header__menu__avatar}>
              
              <span>+{ avatars?.length - 1 }</span>

            </div> }
            

          </div>

          : ''

          // (!noMenu && <NavLink to={'/offers'} className={`${s.header__menu} flex center items-center absolute pointer`}>

          //     <div className = { s.header__menu__point }/>
          //     <div className = {`${ s.header__menu__point } ${ s.header__menu__point__central } relative`}/>
          //     <div className = { s.header__menu__point }/>

          // </NavLink>)

        }

      </header>

      { children }

    </main>

  )

}

export default Carcas;