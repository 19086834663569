import React, { useState, useEffect, useRef } from 'react';
import Carcas from '../../components/containers/carcas';

import { ReactComponent as Warn } from '../../components/icons/warning.svg';


import ButtonChat from '../../components/ui/button.chat';

import s from './chat.module.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFetcher, useNavigate, useSearchParams } from 'react-router-dom';
import { chatApi } from '../../../api/chat';
import { useChatStore } from '../../../store/chat.store';
import { useEcho } from '../../../scripts/hooks/useEcho';
import { decodeText } from '../../../scripts/code.text';
import Loader from '../../components/ui/loader';
import { userApi } from '../../../api/user';
import { Store } from 'react-notifications-component';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useTranslation } from 'react-i18next';
import { MESSAGE, formatTime, getCommonMessages, getDateString, getMessagesForBuyer, getMessagesForSeller } from './messages';
import { ControlPanel, UserPanel } from './control.panel';
import Controls from './controls';

const statuses = {

  new: "new",
  inprocess: "inprocess",
  done: "done",
  dispute: "dispute",
  dispute_canceled: "dispute-canceled"

}

/*Чат*/

const roles = {

  buyer: 'buyer',
  seller: 'seller',

}

const Chat = ({ title = "Новый чат" }) => {


  const [params] = useSearchParams()
  const chatId = params.get('id')
  const { t } = useTranslation()

  const [requisites_value, change_requisites] = useState('');
  const [requisites_link, change_requisites_link] = useState('');

  const chatBody = useRef(null)

  const [open, setOpen] = useState(false);


  const messages = useChatStore(state => state.messages)
  const list = useChatStore(state => state.list)
  const status = useChatStore(state => state.status)
  const status_buyer = useChatStore(state => state.status_buyer)
  const status_seller = useChatStore(state => state.status_seller)

  const setState = useChatStore(state => state.setState)

  let requisites = ''

  list && list.forEach(item => {
    if (messages[item]?.message_data?.requisites?.value) requisites = messages[item]?.message_data?.requisites?.value
  })

  const { data: chatInfo, isLoading: chatInfoIsLoading, isError, isSuccess: chatInfoDataLoaded } = useQuery({

    queryKey: ['chatInfo', chatId],
    queryFn: async () => {

      const res = await chatApi.getChatInfo({ chat_id: +chatId })
      
      if ( res.code === 404 ) return navigate('notFound')

      return res

    }
    

  })


  const { data: activeUserData, isSuccess: userDataLoaded } = useQuery({

    queryKey: ['active_user'],
    queryFn: () => userApi.getActiveUser()

  })

  const { data: membersAvatars } = useQuery({

    queryKey: [ 'membersAvatars', chatId ],
    queryFn: () => chatApi.getMembersAvatars({ chatId }),

  })


  const deleteChatMutation = useMutation({

    mutationFn: () => chatApi.deleteChat(+chatInfo.data.id),
    onSuccess: () => navigate('/')

  })

  const finishDeal = useMutation({

    mutationFn: () => chatApi.finishDeal(chatId),

  })


  const startDealMutation = useMutation({
    mutationFn: () => chatApi.startOffer(chatInfo.data.id, requisites_link || requisites_value),
    onSuccess: (res) => {


      if (!res.status) {

        Store.addNotification({
          title: "Err",
          message: res.message + ' ' + res.code,
          type: "danger",
          insert: "top",
          container: "top-full",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      } else {

      }

    }
  })


  const we = userDataLoaded && chatInfoDataLoaded && chatInfo.data.members.find(member => {
    return member.user.id === activeUserData.data.id
  })

  let activeUserStatus = we.role === roles.seller ? status_seller : status_buyer

  const companion = userDataLoaded && chatInfoDataLoaded && chatInfo.data.members.find(member => {

    const companionRole = we.role === roles.buyer ? roles.seller : roles.buyer

    return member.user.id !== activeUserData.data.id && member.role === companionRole
  })

  useEffect(() => {

    if (!chatInfo) return

    setState(

      chatInfo?.data?.messages,
      chatInfo?.data?.list,
      chatInfo?.data?.deal?.status,
      chatInfo?.data?.deal?.status_buyer,
      chatInfo?.data?.deal?.status_seller,

    )

  }, [chatInfo])


  useEcho({

    chanel: `Chat.${chatId}.Messages`,
    listener: 'newMessage',
    callBack: ( data ) => {

      setState(

        data.messages,
        data.list,
        data.deal.status,
        data.deal.status_buyer,
        data.deal.status_seller,

      )
    
      if ( !chatBody.current ) return

      chatBody.current.scrollTop = chatBody.current.scrollHeight

    }

  })


  const startDisputeMutation = useMutation({
    mutationFn: () => chatApi.startDispute(chatId)
  })

  const finishDisputeMutation = useMutation({
    mutationFn: () => chatApi.finishDispute(chatId)
  })

  const navigate = useNavigate()
  if (chatInfo && chatInfo.status === false) navigate('/notFound')

  function toggleSound() { }


  function deleteChat() {

    deleteChatMutation.mutate()

  }

  function startDeal() {

    setOpen(true)

  }

  function endDeal() {

    finishDeal.mutate()
    console.log('done');

  }

  function wasPaid() {

    finishDeal.mutate()

  }

  function startDispute() {

    startDisputeMutation.mutate()

  }

  function endDispute() {

    finishDisputeMutation.mutate()

  }

  function sendReview() {
    navigate(`/rate?id=${chatId}`)
  }

  const [showWarning, setIsShowWarning] = useState(false)


  useEffect(() => {

    setIsShowWarning(we.role === roles.buyer)

  }, [we])

  const [isDealPreOff, setIsDealPreOff] = useState(false);
  const [isDealPreOffActive, setIsDealPreOffActive] = useState(false);
  const [isDealOff, setIsDealOff] = useState(false);
  const [isDealPreStart, setIsDealPreStart] = useState(false);
  const [isDealPrePay, setIsDealPrePay] = useState(false);

  const [userMessage, setUserMessage] = useState("");

  const activeFiles = useChatStore(state => state.activeFiles)
  const removeActiveFile = useChatStore(state => state.removeActiveFile)
  const activeFileValidated = activeFiles[0]?.file || null


  const sendMessageMutation = useMutation({

    mutationFn: (message) => chatApi.addMessage(chatId, message, activeFileValidated),
    onSuccess: () => removeActiveFile()

  })


  function sendMessage(message) {

    sendMessageMutation.mutate(message)
    setUserMessage('')

  }

  const BUTTON_STATES = [

    ( we.role === roles.seller && status === statuses.new) && (

      <div className={`${s.buttons__container} ${isDealPreStart && s.buttons__container__double} flex center items-center`}>

        <ButtonChat

          orangeTheme
          name={ t('chat.startDeal') }
          action={() => startDeal()}

        />

        <ButtonChat

          whiteTheme
          name = { t('chat.delete') }
          action={() => deleteChat()}

        />

      </div>

    ),

    (we.role === roles.buyer && status === statuses.inprocess) && (

      <div className={`${s.buttons__container} ${isDealPreStart && s.buttons__container__double} flex center items-center`}>

        <ButtonChat

          orangeTheme
          name = { t('chat.openDispute') }
          action={() => startDispute()}

        />
  
        <ButtonChat

          purpleTheme
          name={!(status_buyer === statuses.done && status_seller === statuses.done) ? t('chat.paidForIt') : t('chat.completeTransaction')}
          action={() => wasPaid()}
          disabled={status_buyer === statuses.done && !(status_buyer === statuses.done && status_seller === statuses.done)}

        />

      </div>

    ),

    (requisites && we.role === roles.seller && (status !== statuses.inprocess && status !== statuses.done || status_buyer !== statuses.done || status_seller !== statuses.done)) && (

      <div className={`${s.buttons__container} ${isDealPreStart && s.buttons__container__double} flex center items-center`}>

        { status_buyer !== statuses.dispute && status_seller !== statuses.dispute && <ButtonChat

          orangeTheme
          name = { t('chat.openDispute') }
          action={() => startDispute()}

        />}

        { status !== statuses.dispute && <ButtonChat

          purpleTheme
          name={ t('chat.completeTransaction') }
          action={() => endDeal()}
          disabled={status_buyer !== statuses.done}

        />}

      </div>

    ),


    (we.role === roles.seller && chatInfo?.data?.members > 2 && status === statuses.inprocess && status_buyer === statuses.done && status_seller === statuses.done) && (

      <div className={`${s.buttons__container} ${isDealPreStart && s.buttons__container__double} flex center items-center`}>

        <ButtonChat

          orangeTheme
          name = { t('chat.openDispute') }
          action={() => startDispute()}

        />

      </div>

    ),
    

    ( status === statuses.dispute && activeUserStatus === statuses.dispute ) && (

      <div className={`${s.buttons__container} ${isDealPreStart && s.buttons__container__double} flex center items-center mt-0`}>

        <ButtonChat

          whiteTheme
          name = { t( 'chat.cancelDispute' ) }
          action={() => endDispute()}

        />

      </div>

    ),

    we.role === roles.buyer && status === statuses.done && status_buyer === statuses.done && status_seller === statuses.done && (

      <div className={`${s.buttons__container} ${isDealPreStart && s.buttons__container__double} flex center items-center`}>

        {!chatInfo.data.is_reviewed && <ButtonChat

          orangeTheme
          name = { t( 'review.sendReview' ) }
          action={() => sendReview()}

        />}

        <ButtonChat

          whiteTheme
          name = { t( 'chat.delete' ) }
          action={() => deleteChat()}

        />

      </div>

    )

  ]

  useEffect(() => {

    isDealOff === true && setIsDealPreOff(false)

  }, [isDealOff]);

  useEffect(() => {

    isDealPreOff === true && setIsDealPreOffActive(false)

  }, [isDealPreOff]);

  useEffect(() => {

    isDealPreOffActive === true && setIsDealPreOff(false)

  }, [isDealPreOffActive]);
 
  const sendRequisites = () => {

    setOpen(false)
    startDealMutation.mutate()

  }

  const canWrite = status !== statuses.dispute_canceled && status != statuses.done

  const chatStartTime = chatInfo 
    && chatInfo.status === true 
    && chatInfo.data.messages.length > 0 
    && chatInfo.data.messages[chatInfo.data.list[0]].created.split(' ')[1].split(':')[0] + ':' + chatInfo.data.messages[chatInfo.data.list[0]].created.split(' ')[1].split(':')[1]


  useEffect(() => {

    if ( !chatBody.current || !chatInfo ) return

    chatBody.current.scrollTop = chatBody.current.scrollHeight

  }, [ chatInfo, chatBody.current, we ])

  if (chatInfoIsLoading) return <Loader />


  return (
    <>

      {chatInfo && chatInfo.status && <Carcas

        withBack
        chatMode
        chatData = { chatInfo.status === true ? chatInfo : null }
        companion = { companion }
        actionBack = { () => navigate('/') }
        avatars = { membersAvatars }

      >

        <main className={`${s.chat} flex column relative`}>

          <RequisitesPoopUp

            change_requisites = { change_requisites }
            change_requisites_link = { change_requisites_link } 
            open = { open }
            requisites_link = { requisites_link }
            requisites_value = { requisites_value }
            sendRequisites = { sendRequisites }

            
          />

          {chatInfo.data.deal &&

            <Controls

              sending_currency_value={chatInfo.data.deal.value_from}
              sending_currency_short={chatInfo.data.offer.currency_from}
              sending_currency={chatInfo.data.offer.currency_from}
              getting_currency_value={chatInfo.data.deal.value_to}
              getting_currency_short={chatInfo.data.offer.currency_to}
              getting_currency={chatInfo.data.offer.currency_to}
              soundControl={toggleSound()}
              isMuted = { chatInfo?.data?.muted }

            />

          }

          <ControlPanel

            showDelButton={status === statuses.done || status === statuses.new }
            dealStart={false}
            dealPreOff={false}
            manager_name={chatInfo.status && chatInfo.data.members[2]?.user?.name}
            chatInfo={chatInfo}

            deal_id={chatInfo?.data?.deal?.id}
            isDealPreStart={false}
            deleteChat={() => deleteChat()}

          />

          {canWrite && <UserPanel

            userMessage={userMessage}
            setUserMessage={setUserMessage}
            sendMessage={() => sendMessage(userMessage)}

          />}

          { BUTTON_STATES }

          <div className={`${s.chat__body} ${!canWrite && s.chat_canwrite} flex column`}>

            <div className={`${s.warning} flex column items-center`}>

              {status === statuses.new && we.role === roles.buyer &&

                <>

                  <Warn className={s.warning__icon}></Warn>

                  <p className={`${s.warning__text} text-14-m text-center`}>

                    {t('chat.warningText')}

                  </p>

                </>

              }

            </div>

            <div ref = { chatBody } className={`${s.chat__content} flex column relative`}>

              <p className={`${s.bot__text} text-14-m`}>

                {chatInfo?.data?.list && getDateString(chatInfo.data.messages[chatInfo.data.list[0]].created) + ' '}
                {chatInfo.status && chatInfo.data.list?.length && chatInfo.data.messages[chatInfo.data.list[0]].created.split(' ')[0].split('-').join('.')}

              </p>

              <p className={`${s.bot__text} text-14-m`}>

                <span>{title}</span> {chatStartTime}

              </p>

              { status === statuses.new && we.role === roles.buyer && <p className={`${s.bot__text} text-14-m`}>

                Вы создали заявку на обмен {chatInfo.data.deal.value_from} {chatInfo.data.offer.currency_from} {chatInfo.data.offer.currency_from}&nbsp;
                на {chatInfo.data.deal.value_to} {chatInfo.data.offer.currency_to} {chatInfo.data.offer.currency_to}.&nbsp;
                Не переводите деньги продавцу, пока это уведомление не исчезнет!<br />
                Этот чат исчезнет автоматически через 60 минут, если <br />
                этого не произойдет

                </p>

              }

              {list && we && list.length && list.map((id, key) => {

                const el = messages[id]
                const requisites = messages[id]?.message_data?.requisites?.value

                if (requisites) return (
                  <div className={`${s.deal_start} mt-m-15`}>
                    <span className={s.deal_title}>{`${ t('chat.dealStarted') }: ` + formatTime(el.created)}</span>
                    <span>Реквизиты: {requisites}</span>
                  </div>
                )

                if (!Array.isArray(el.message_data)) {


                  if (we.role === roles.buyer) getMessagesForBuyer(el.message_data.title, el)
                  if (we.role === roles.seller) getMessagesForSeller(el.message_data.title, el)

                  return getCommonMessages( el.message_data.title, el, el.user_id === we.user.id ? we : companion )


                }

                return <MESSAGE

                  el={el}
                  file={el.file}
                  key={key}
                  isModer={we.user.id !== el.user_id && companion.user.id !== el.user_id}
                  content={decodeText(el.message, 'p')}
                  type={we.user.id === el.user_id ? 1 : 0}

                />


              })}

            </div>

          </div>

        </main>

      </Carcas>}

    </>
  )

}

const RequisitesPoopUp = ({ 

  open,
  requisites_value,
  change_requisites,
  change_requisites_link,
  requisites_link,
  sendRequisites,

}) => {

  return (

    <Popup open = { open } modal>

      <div className = { s.poopUp }>

        <span className = { s.poopUp_title }>Ваши реквизиты</span>

        <span className = { s.sub_title }>Введите текстовую информацию о реквизитах</span>

        <input

          className = { s.input } 
          value = { requisites_value } 
          onChange = { e => {

            change_requisites( e.target.value )
            change_requisites_link('')

          }}

        />

        <span className = { s.sub_title }>Добавьте ссылку для прямого перехода к оплате</span>
        
        <input className = { s.input } onChange={e => {

          change_requisites_link( e.target.value )
          change_requisites('')

        }} value = { requisites_link } />

        <button onClick = { sendRequisites } >Отправить</button>
        
      </div>
    
    </Popup>

  )

}

export default Chat;