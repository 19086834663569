import React, { useState } from 'react';

import { ReactComponent as ArrowRight } from '../../icons/RightArrow.svg';
import { ReactComponent as Faq } from '../../icons/faq.svg';

import s from './faq.module.scss';

/*отправка & получение валют*/

const FAQ = ({
  
  children

}) => {

  const [ isOpened, setIsOpened ] = useState( false );

  function toggle() { setIsOpened(isOpened) };

  return (

      <section className = {`${ s.faq } ${ isOpened && s['faq--opened'] }`}>

      <button

        onClick={() => window.Telegram.WebApp.openLink('https://twix.im/knowledge-base')}
          className = "flex items-center between pointer"
          
        >

          <div className = "flex items-center">

            <Faq className = { s.faq__icon }/>

            <p className = { s.faq__title }>FAQ</p>

          </div> 

          <ArrowRight className = { s.faq__arrow }/>    

      </button>  

        <div>

        {/* { children } */}

        </div>

      </section>

  )

}

export default FAQ;