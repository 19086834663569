import React, { useEffect, useState } from 'react';
import Exchanger from '../../components/containers/exchanger';
import { ReactComponent as Shifter } from '../../components/icons/shifter.svg';

import s from './exchanger.module.scss';
import ButtonLink from '../../components/ui/button.link';
import FAQ from '../../components/containers/faq';
import DealsDesk from '../../components/ui/deals.desk';
import { useQuery } from '@tanstack/react-query';
import { ratesApi } from '../../../api/rates';
import { useTranslation } from 'react-i18next';
import { useOffersStore } from '../../../store/offers.store';

/*Обменник*/

const ExchangerView = () => {

  const params = useOffersStore(state => state.params)
  const [isReversed, setIsReversed] = useState(params?.isReversed ? params?.isReversed : false);
  const setOffersParams = useOffersStore(state => state.setParams)

  function reverse() { setIsReversed(!isReversed) }
  const { t, i18n } = useTranslation()

  const { data: currencyList, isLoading } = useQuery({

    queryKey: ['currency'],
    queryFn: () => ratesApi.getCurrencies(),

  })

  const exchangerStateOne = useState(params?.exchangerStateOne)
  const exchangerStateSecond = useState(params?.exchangerStateSecond)

  const exchangerStateOneValue = useState(params?.exchangerStateOneValue || null)
  const exchangerStateSecondValue = useState(params?.exchangerStateSecondValue || 0)

  const exchangerStateOnePayType = useState(params?.exchangerStateOnePayType)
  const exchangerStateSecondPayType = useState(params?.exchangerStateSecondPayType)

  // отдать
  const fromObject = {

    currency: isReversed ? exchangerStateSecond[0]?.symbol : exchangerStateOne[0]?.symbol,
    value: isReversed ? exchangerStateSecondValue[0] : exchangerStateOneValue[0],
    payType: isReversed ? exchangerStateSecondPayType[0]?.id : exchangerStateOnePayType[0]?.id,

  }

  // получить
  const toObject = {

    currency: !isReversed ? exchangerStateSecond[0]?.symbol : exchangerStateOne[0]?.symbol,
    value: !isReversed ? exchangerStateSecondValue[0] : exchangerStateOneValue[0],
    payType: !isReversed ? exchangerStateSecondPayType[0]?.id : exchangerStateOnePayType[0]?.id,

  }

  let filterString = ''

  if (fromObject.payType) filterString = filterString + `&from_pay_type=${fromObject.payType}`
  if (toObject.payType) filterString = filterString + `&to_pay_type=${toObject.payType}`


  if (fromObject.value && fromObject.value != 0) filterString = filterString + `&min_amount_sale=${fromObject.value}`
  if (toObject.value && toObject.value != 0) filterString = filterString + `&min_amount_buy=${toObject.value}`



  useEffect(() => {

    setOffersParams({

      exchangerStateOne: exchangerStateOne[0],
      exchangerStateSecond: exchangerStateSecond[0],

      exchangerStateOneValue: exchangerStateOneValue[0],
      exchangerStateSecondValue: exchangerStateSecondValue[0],

      exchangerStateOnePayType: exchangerStateOnePayType[0],
      exchangerStateSecondPayType: exchangerStateSecondPayType[0],

      isReversed: isReversed

    })

  }, [exchangerStateOne[0],
  exchangerStateSecond[0],
  exchangerStateOneValue[0],
  exchangerStateSecondValue[0],
  exchangerStateOnePayType[0],
  exchangerStateSecondPayType[0],
    isReversed,
  ])

  useEffect(() => {

    if (exchangerStateOne[0] && exchangerStateSecond[0]) return
    exchangerStateOne[1](currencyList?.data[0])
    exchangerStateSecond[1](currencyList?.data[1])

  }, [currencyList])


  return (

    <div className={`${s.container}`}>

      <div className={`flex column ${s.container__changer} ${isReversed && s['container__changer--reversed']}`}>

        <Exchanger

          getType={!isReversed ? true : false}
          sendType={!isReversed ? false : true}
          currencyList={currencyList?.data}
          choisePayment
          state={exchangerStateOne}
          stateValue={exchangerStateOneValue}
          onChange={() => exchangerStateSecondValue[1](0)}
          payTypeState={exchangerStateOnePayType}

        />

        <div

          onClick={() => reverse()}
          className={`${s.shifter} flex center items-center pointer self-center relative`}

        >

          <Shifter className={`${s.shifter__icon}`} />

        </div>

        <Exchanger

          getType={!isReversed ? false : true}
          sendType={!isReversed ? true : false}
          choisePayment
          onChange={() => exchangerStateOneValue[1](0)}
          state={exchangerStateSecond}
          currencyList={currencyList?.data}
          stateValue={exchangerStateSecondValue}
          payTypeState={exchangerStateSecondPayType}

        />

      </div>

      <ButtonLink

        url={`/offers?from=${fromObject.currency}&to=${toObject.currency}${filterString}`}
        // action = { getOffer() }
        className={s.button}
        name={t('ui.view_offers')}
        disabled={!(fromObject?.value > 0 || toObject?.value > 0)}

      />

      <FAQ>

        { t('ui.question') }?<br />
        { t('ui.answer') }!

      </FAQ>

      <DealsDesk />

    </div>

  )

}

export default ExchangerView;