import { useEffect, useState } from 'react';
import './App.css';
import AppRouter from './react/pages/router';
import { userApi } from './api/user';

import io from 'socket.io-client'
import Echo from 'laravel-echo'

import './scripts/i18n'

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next';
import Loader from './react/components/ui/loader';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient()

function App() {

  const [_, i18n] = useTranslation()

  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {

    // открываем приложение на всю высоту
    window.Telegram.WebApp.expand()
    // console.log('some')

    userApi.getTokenTwa().then((res) => {

      const echo = new Echo({

        client: io,
        broadcaster: 'socket.io',
        host: `https://api.twix.im:8443`,
        namespace: '',

        auth: { headers: { Authorization: `Bearer ${res}` } }

      })

      window.echo = echo

      echo.connector.socket.on('connect', function () {

        setIsConnected(true)

      });

    })



    window.Telegram.WebApp.initDataUnsafe.user && i18n.changeLanguage(window.Telegram.WebApp.initDataUnsafe.user.language_code);
    //window.Telegram.WebApp.colorScheme
    console.log('window.Telegram.WebApp.initDataUnsafe', window.Telegram.WebApp.colorScheme);
    document.body.className += window.Telegram.WebApp.colorScheme;
    // const container = document.getElementById('root');
  }, [])


  if ( !isConnected ) return <Loader />
  // return <Loader />

  return (

    <QueryClientProvider client = { queryClient }>

      <BrowserRouter>

        <ReactNotifications />
        <AppRouter />

      </BrowserRouter>

    </QueryClientProvider>

  );

}

export default App;
