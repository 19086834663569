import React from 'react';
import s from'./textfield.module.scss';

/*поле ввода*/

const Textfield = ({
	
	set,
	value = "",
	type = "text",
	placeholder = "",
	className,
  rounded = false,
	multiline = false,
	multilineRows = 5,
  onKeyUp = () => { },
  pattern

}) => (
	
  !multiline

    ?	<input 
    
      value={value}
      pattern={pattern}
      type={type}
      placeholder={placeholder}
      onKeyUp={onKeyUp}
      onChange={(event) => { set(event.target.value) }}
      className={`${s.textfield} ${className} ${rounded && s['textfield--rounded']}`}
      onBlur={() => console.log()}

      />

    :	<textarea
    
        // ref = { refDOM }
        rows = { multilineRows }
        placeholder = { placeholder }
        onKeyUp = { onKeyUp }
        onChange = { (event) => { set( event.target.value) } }
        className = {`${ s.textfield } ${ s.textfield__area } ${ className } ${ rounded && s['textfield--rounded'] }`}
    
    >

      { value }

    </textarea>

);

export default Textfield;