import { axiosInstance } from "../instance"
import CryptoJS from "crypto-js";

export const chatApi = {

  async getChatList () {

    return (await axiosInstance.post('/v1/chat/list')).data

  },

  async createChat(data) {

    return (await axiosInstance.post('/v1/chat/create', data)).data

  },


  async sendReview({ chat_id, status, text }) {

    return (await axiosInstance.post('/v1/review/add', {

      chat_id,
      status,
      text,
      hash: true,

    })).data

  },

  async getChatInfo(params) {

    return (await axiosInstance.post('/v1/chat/get', { ...params, hash: true })).data

  },

  async addMessage(chatId, text, file) {

    const form = new FormData()

    form.append('chat_id', chatId)
    if (text) form.append('message', convertMessage(text))
    if (file) form.append('file', file)
    form.append('hash', true)


    return (await axiosInstance.post('/v1/chat/message/add', form)).data

  },

  async deleteChat(id) {

    return (await axiosInstance.post('/v1/chat/delete', { chat_id: id, hash: true })).data

  },

  async startOffer(id, requisites) {

    return (await axiosInstance.post('/v1/chat/deal/start', {

      chat_id: +id,
      hash: true,
      requisites,

    })).data

  },

  async startDispute(chat_id) {

    return (await axiosInstance.post('/v1/chat/dispute/start', {
      chat_id,
      hash: true
    })).data
  },

  async finishDispute(chat_id) {

    return (await axiosInstance.post('/v1/chat/dispute/finish', {
      chat_id,
      hash: true
    })).data

  },

  async finishDeal(chat_id) {

    return (await axiosInstance.post('/v1/chat/deal/finish', {
      chat_id,
      hash: true
    })).data

  },

  async setRequisites({ initiator_id, offer_id, owner_id, identifier, requisites }) {

    const data = await axiosInstance.post('/v1/user/deal/requisites/get', {

      initiator_id,
      offer_id,
      owner_id,

    })

    const res = await axiosInstance.post('/v1/user/deal/requisites/store', {

      identifier,
      offer_id,
      ownIdentifier: data.data.data.owner_requisites.identifier,
      ownRequisites: data.data.data.owner_requisites.requisites,
      requisites,

    })

    return res

  },

  async getMembersAvatars({ chatId }) {

    const res = await axiosInstance.post('/v1/chat/participants', {

      chat_id: chatId,
      hash: true,

    })

    return res.data.data

  },

  async muteChat({ chatId, value }) {

    const res = await axiosInstance.post('/v1/chat/mute', {

      chat_id: chatId,
      mute: value,
      hash: true,

    })

    return res.data.data

  },

}


const convertMessage = (message) => {

  const
    s = "||",
    l = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex),
    a = CryptoJS.AES.encrypt(message, l) + s + l;

  return utf8ToHex(a)

}

const utf8ToHex = (e) => {
  return [...e].map(t => t.charCodeAt(0) < 128 ? t.charCodeAt(0).toString(16).padStart(2, "0") : encodeURIComponent(t).replace(/%/g, "").toLowerCase()).join("")
}
