import { useEffect, useState } from "react";

export const useEcho = ({ callBack, chanel, listener  }) => {

  const [ haveConnect, setHaveConnect ]  = useState( false ) 

  useEffect(() => {

    if ( !window.echo || haveConnect ) return

    window.echo.private( chanel ).listen( listener , ( data ) => callBack(data) )
    setHaveConnect( true )

    return () => window.echo.leaveChannel( chanel );

  }, [ window.echo ])

}