import { ReactComponent as Send } from '@/react/components/icons/send.svg';
import { ReactComponent as Attach } from '@/react/components/icons/attach.svg';
import { ReactComponent as Layers } from '@/react/components/icons/layers.svg';
import { ReactComponent as Attention } from '@/react/components/icons/attention.svg';
import s from '../chat.module.scss'
import { Store } from 'react-notifications-component';
import ButtonChat from '../../../components/ui/button.chat';
import { useChatStore } from '@/store/chat.store';
import Textfield from '../../../components/ui/textfield';

export const ControlPanel = (props) => {

  const {

    showDelButton,
    deleteChat = () => { },
    chatInfo,

  } = props

  const showNotification = () => Store.addNotification({

    title: "Добавлен в буфер обмена",
    type: "info",
    insert: "top",
    container: "top-full",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],

    dismiss: {

      duration: 2000,
      onScreen: true

    }

  })

  const clickFistItem = () => {
    navigator.clipboard.writeText(chatInfo?.data?.deal_requisites?.seller_requisites?.identifier) && showNotification()
  }
  const clickSecondItem = () => { navigator.clipboard.writeText(chatInfo.data.deal_requisites.seller_requisites.requisites) && showNotification() }

  return (

    <div className={`${s.chat__controls__panel} flex between items-center absolute`}>


      {chatInfo?.data?.deal_requisites?.seller_requisites?.identifier && <button onClick={clickFistItem} className={`${s.button} pointer`} >

        <div className={`${s.chat__controls__panel__graybox} flex between items-center`}>

          <Attention className={`${s.chat__controls__panel__graybox__icon}`} />

          <p className={`${s.chat__controls__panel__graybox__text} text-14-m`}>{chatInfo.data.deal_requisites.seller_requisites.identifier}</p>

        </div>

        <Layers

          className={s.chat__controls__panel__layers}

        />

      </button>

      }

      {chatInfo?.data?.deal_requisites?.seller_requisites?.requisites && <button onClick={clickSecondItem} className={`${s.button} pointer`} >

        <div className={`${s.chat__controls__panel__graybox} flex between items-center pointer`}>

          <Attention className={`${s.chat__controls__panel__graybox__icon}`} />

          <p className={`${s.chat__controls__panel__graybox__text} text-14-m`}>{chatInfo.data.deal_requisites.seller_requisites.requisites}</p>

        </div>

        <Layers

          className={s.chat__controls__panel__layers}

        />

      </button>}

      { showDelButton &&

        <ButtonChat

          action={() => deleteChat()}
          name="Удалить чат"
          className={s.top_del_button}

        />

      }


    </div>

  );

};

export const UserPanel = ({

  userMessage,
  setUserMessage,
  uploadFiles = () => { },
  sendMessage = () => { },
  status

}) => {


  const activeFiles = useChatStore(state => state.activeFiles)
  const setActiveFile = useChatStore(state => state.setActiveFile)
  const removeActiveFile = useChatStore(state => state.removeActiveFile)

  const changeFile = (e) => {

    const imageFile = e.target.files[0];

    const reader = new FileReader();

    reader.addEventListener("load", (readerE) => {

      setActiveFile(readerE.target.result, imageFile);

    });

    reader.readAsDataURL(imageFile);

  }

  
  return (

    <div className={`${s.chat__controls__userpanel} flex between items-center absolute`}>

      {activeFiles[0] && <img onClick={removeActiveFile} className={s.controls_img} src={activeFiles[0].fileUrl} alt="file" />}

      <div className={s.chat_controls_main}>

        <label className={s.upload_file}>

          <input type='file' accept="image/png, image/gif, image/jpeg" onChange={changeFile} />

          <Attach

            onClick={() => uploadFiles()}
            className={`${s.chat__controls__userpanel__attach} pointer`}

          />

        </label>

        <Textfield

          rounded
          className = { s.textfield }
          value = { userMessage }
          set = { setUserMessage }
          placeholder="Текст сообщения..."

        />

        <Send

          onClick={() => sendMessage()}
          className={`${s.chat__controls__userpanel__send} pointer`}

        />

      </div>

    </div>

  );

};
