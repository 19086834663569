import React from 'react';

import { ReactComponent as ArrowRight } from '../../icons/ArrowRightLong.svg';

import s from './offer.module.scss';
import ButtonLink from '../../ui/button.link';
import { useTranslation } from 'react-i18next';
import TagList from './tags.list';


const Offer = (props) => {

  const { t } = useTranslation()

  const {

    id = 1,
    name = "",
    positive_reviews = 0,
    neutral_reviews = 0,
    negative_reviews = 0,
    sending_currency_value = 0,
    sending_currency_short = "",
    sending_currency_type,
    sending_currency = 0,
    getting_currency_value = 0,
    getting_currency_short = "",
    getting_currency_type = "",
    getting_currency = "",
    el,
    isDisabled,

  } = props

  return (

    <div className={`${s.offer}`}>

      <p className={`${s.offer__name} text-24-m`}>{name}</p>

      <div className="flex between width-full">

        <div className="flex between ">

          <p className={`${s.offer__deals} text-16-m relative`}>

            {el.deals_count} {t('offers.transactions')} &nbsp;
            <span>{positive_reviews}</span>/
            <span>{neutral_reviews}</span>/
            <span>{negative_reviews}</span>

          </p>

        </div>

        <TagList el={el} />

      </div>

      <div className={s.offer__liner} />

      <div className="flex between width-full">

        <div className="flex between">

          <p className={`${s.offer__deals} text-14-m`}>

            {sending_currency_value}&nbsp;
            {sending_currency_short}&nbsp;

            <span className={s.offer__deals__graytext}>

              {sending_currency}&nbsp;
              {sending_currency_type}

            </span>

          </p>

          <ArrowRight className={`${s.offer__arrow} relative`} />

          <p className={`${s.offer__deals} text-14-m flex flex-wrap`}>

            {getting_currency_value}&nbsp;
            {getting_currency_short}&nbsp;

            <br />

            <span className={`${s.offer__deals__graytext}`}>

              {getting_currency}&nbsp;
              {getting_currency_type}

            </span>

          </p>

        </div>

      </div>

      <p className={`${s.offer__deals__graytext} ${s.offer__deals__graytext__thin} text-14-m`}>

        { el?.info }

      </p>

      {!isDisabled && <ButtonLink

        name={t('ui.exchange')}
        url={`/bid?id=${id}`}

      />}

    </div>

  )

}

export default Offer;