import { axiosInstance } from "../instance"

export const offerApi = {

  async getOffersList(params, limit) {

    const res = await axiosInstance.post(`/v1/offer/list`, params, {
      params: {
        limit: limit,
        start: 0,
      }
    })

    return res.data

  },

  async getRequisites (params) {

    const res = await axiosInstance.post(`/v1/user/deal/requisites/get`, params, {

      params: params

    })

    return res.data

  },
}
