import { create } from 'zustand'

export const useChatStore = create((set) => ({

  messages: [],
  list: [],
  activeFiles: [],
  status: '',
  status_seller: null,
  status_buyer: null,


  setState: (messages, list, status, status_buyer, status_seller) => set(state => ({

    ...state,
    messages,
    list,
    status,
    status_buyer,
    status_seller,

  })),

  setActiveFile: (fileUrl, file) => set(state => ({

    ...state,
    activeFiles: [{ fileUrl, file }]

  })),
  removeActiveFile: () => set(state => ({ ...state, activeFiles: [] }))

}))