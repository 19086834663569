import React, { Fragment, useEffect, useState } from 'react';

import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowDownPurple } from '../../icons/ArrowDownPurple.svg';

import s from './exchanger.module.scss';
import Textfield from '../../ui/textfield';
import { useQuery } from '@tanstack/react-query';
import { ratesApi } from '../../../../api/rates';
import { useTranslation } from 'react-i18next';

/*отправка & получение валют*/

const Exchanger = ({

  getType = true,
  sendType = true,
  choisePayment = false,
  currencyList = [],
  setParams = () => { },
  state,
  stateValue,
  payTypeState,
  onChange

}) => {

  const  { t } = useTranslation()

  const [currentValue, setCurrentValue] = state;
  const [isListShow, setIsListShow] = useState(false);
  const [value, setValue] = stateValue;
  const [payListIsOpen, setPayListIsOpen] = useState(false)
  const [activePay, setActivePay] = payTypeState

  console.log(currencyList);



  function toggleList() { setIsListShow(!isListShow) };
  function closeList() { setIsListShow(false) };

  const { data: payTypes } = useQuery({

    queryKey: ['payTypes', currentValue?.symbol],
    queryFn: () => ratesApi.getPayTypes(currentValue?.symbol),

  })

  const closeAll = () => {

    setPayListIsOpen(false)
    closeList(false)

  }

  useEffect(() => {

    window.addEventListener('click', closeAll)

    return () => window.removeEventListener('click', closeAll)

  }, [])

  useEffect(() => {

    if (!payTypes) return

    const findPay = payTypes.data.find(pay => activePay?.id === pay.id)

    setActivePay(findPay ? findPay : payTypes.data[0])

    if (!payTypes.data[0] && !findPay) {
      return setActivePay(null)
    }


  }, [payTypes])


  const [paySearch, setPaySearch] = useState('')
  const [coinSearch, setCoinSearch] = useState('')

  return (

    <Fragment>

      { currentValue ?

        <div className={`${s.exchanger} ${choisePayment && payTypes?.data?.length && s.exchanger__with_payment}`}>

          <p className={`${s.exchanger__title}`}>{getType ? t('ui.send') : sendType && t('ui.get')}</p>

          <div className={`${s.exchanger__input} flex items-center between relative`}>

            <div

              onClick={(e) => { e.stopPropagation(); toggleList() }}
              className={`${s.exchanger__currency} ${isListShow && s['exchanger__currency--opened']} flex items-center between pointer`}

            >

              <img src = {`/coins/${ currentValue.name.toLowerCase().replace(' ', '')  }.svg`} className={`${s.exchanger__currency__icon}`} />

              <p className={`${s.exchanger__currency__name}`}>{currentValue?.symbol}</p>

              <ArrowDown className={s.exchanger__currency__arrow} />

            </div>

            <div

              className={`
            
              absolute
              ${s.exchanger__currency__list}
              ${isListShow && s['exchanger__currency__list--opened']}
              ${s.select_section}
            
            `}

            >

              <span className={`${s.layout} ${isListShow && s.active}`} />

              <ul>

                <input

                  placeholder={t('ui.name')}
                  type="text"
                  onClick={e => e.stopPropagation()}
                  value={coinSearch}
                  onChange={(e) => setCoinSearch(e.target.value)}

                />

                { currencyList.filter(el => el.symbol.toLowerCase().includes(coinSearch.toLowerCase())).map((el, key) => (


                  <li

                    key={key}
                    onClick={() => { setCurrentValue(el); closeList(); setParams(el, value); }}
                    className={`${s.exchanger__currency__list__item} flex items-center`}

                  >


                    <img src = {`/coins/${ el.name.toLowerCase().replace(' ', '') }.svg`} className={`${s.exchanger__currency__icon}`} />
                    <p className={`${s.exchanger__currency__name}`}>{el.symbol}</p>


                  </li>

                ))}

              </ul>

            </div>

            <div className={`${s.exchanger__currency__container} flex items-center`}>

              <Textfield

                set={value => {
                  if (!value.replace(',', '.').match(/^[0-9]*\.?[0-9]*$/)) return
                  onChange()
                  setValue(value.replace(',', '.').match(/^[0-9]*\.?[0-9]*$/)[0])
                }}
                value={value}
                pattern="/[0-9]|\./"
                placeholder="0.123"
                className={s.exchanger__currency__value}
                type='text'

              />

              <p className={`${s.exchanger__currency__type} text-16-m`}>{currentValue?.name}</p>

            </div>

          </div>

          { choisePayment && payTypes?.data?.length ?

            <div className={`flex ${s.payment} flex items-center between relative`}>

              <div className={`flex ${s.payment__title} text-18-m`}>{t('ui.pay_type')}</div>

              <div

                onClick={(e) => {

                  e.stopPropagation()
                  setPayListIsOpen(prev => !prev)

                }}

                className={`${s.pay_button} flex items-center pointer`}

              >

                {activePay && <p className={`flex ${s.payment__option} text-16-m`}>
                  
                  <img className={ s.pay_icon } src={`/coins/${ activePay.name.toLowerCase().replace('/', '_') }.svg`} alt="pay icon" />
                  <span>{activePay.name}</span>
                  
                </p>}

                <ArrowDownPurple className={s.payment__arrow} />

              </div>

              <div className={s.select_section}>

                <span className={`${s.layout} ${payListIsOpen && s.active}`} />

                {payListIsOpen && <ul className={`${s.selectList} ${s.selectList}`}>

                  <input

                    placeholder={t('ui.bankName')}
                    type="text"
                    onClick={e => e.stopPropagation()}
                    value={paySearch}
                    onChange={(e) => setPaySearch(e.target.value)}

                  />

                  {payTypes.data.filter(el => el.name.toLowerCase().includes(paySearch.toLowerCase())).map(el => (

                    <li onClick={() => setActivePay(el)} key={el.id}>
                      <img className={ s.pay_icon } src={`/coins/${ el.name.toLowerCase().replace('/', '_') }.svg`} alt="" />
                      <span>{el.name}</span>
                    </li>

                  ))}

                </ul>}

              </div>

            </div> : ''

          }

        </div>
        : ''

      }

    </Fragment>

  )

}

export default Exchanger;