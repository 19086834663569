import React from 'react';
import s from './button.chat.module.scss';

/*кнопка чата*/

const ButtonChat = ({
  
  name = "ок",
  className,
  action = () => {},
  whiteTheme,
  orangeTheme,
  purpleTheme,
  purpleGhostTheme,
  disabled

}) => {

  return (

    <button
    
      onClick = { action }
      disabled={disabled}

      className = {`
      
        ${ s.button }
        ${ whiteTheme && s.button__white }
        ${ orangeTheme && s.button__orange }
        ${ purpleTheme && s.button__purple }
        ${ purpleGhostTheme && s.button__purple__ghost }
        ${ className } pointer
        
      `}
      
    >

      { name }

    </button>

  )

}

export default ButtonChat;