import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/ui/button';
import Carcas from '../../components/containers/carcas';
import Textfield from '../../components/ui/textfield';
import s from './rate.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query'
import { chatApi } from '../../../api/chat';


/*Оценка*/

const Rate = ({ id = 1 }) => {

  const [ review, setReview ] = useState( "" );

  const [activeStatus, setActiveStatus] = useState('positive')
  const statuses = ['positive', 'negative', 'neutral']

  const [params] = useSearchParams()
  const navigate = useNavigate()
  const chatId = params.get('id')


  const { t, i18n } = useTranslation()

  const sendReviewMutation = useMutation({

    mutationFn: () => chatApi.sendReview({
      chat_id: chatId,
      status: activeStatus,
      text: review,
    }),

    onSuccess: () => {
      navigate('/offers')
    }

  })



  function sendReview() {
    sendReviewMutation.mutate()
  }

  return (

    <Carcas grayTheme withBack actionBack = { () => navigate('/') }>

      <main className={`${s.content} ${sendReviewMutation.isPending && s.pending}`}>

        <p className={`${s.title} text-32-m`}>{t('review.title')}</p>

        <div className="flex">

          {
            statuses.map(status => (

              <RateButton

                key={status}
                action={() => setActiveStatus(status)}
                type={status}
                name={t(`review.estimation.${status}`)}
                isActive={status === activeStatus}

              />

            ))
          }

        </div>

        <Textfield
      
          value = { review }
          set = { setReview }
          multiline
          multilineRows = { 3 }
          className = { s.textfield }
          placeholder={t('review.placeholder')}
        
        />

        <Button

          className = { s.button }
          name={t('review.sendReview')}
          action={sendReview}

        />

      </main>

    </Carcas>

  )

}

export default Rate;

const RateButton = ({ type = "neutral", name = "Нейтрально", action = () => { }, isActive }) => (

  <div
  
    onClick = { action }

    className = {`
    
      ${ s.rate__button }

      ${ type === "positive"
      
        ? s.rate__button__positive          
        
        : type === "negative" && s.rate__button__negative
      }

      ${ isActive && s.active }
      
      flex center items-center pointer
      
  `}>

    <p className = {`${ s.rate__button__name } text-14-m`}>{ name }</p>

  </div>

);