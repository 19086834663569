import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useOffersStore = create(persist((set) => ({

  params: {

  },

  setParams: (params) => set(state => ({

    ...state,
    params: params,

  })),


}), {
  name: 'offersStorage_test'
}))