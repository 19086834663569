import React from 'react';
import Carcas from '../../components/containers/carcas';
import ExchangerView from '../../views/exchanger.view';

/*Домашняя Страница*/

const Home = () => {

  const LoggedOn = true;

  return (

    <Carcas lightGrayTheme={LoggedOn && true} noMenu>

      <ExchangerView />

    </Carcas>

  )

}

export default Home;