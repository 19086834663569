import React from 'react';
import s from './button.module.scss';

/*кнопка*/

const Button = ({
  
  className,
  name = "ок",
  small = false,
  light = false,
  action = () => {},
  isDisabled = false

}) => {

  return (

    <button
    
      onClick = { action }

      className = {`
      
        ${ s.button }
        ${ small && s.button__small }
        ${ light && s.button__light }
        ${ isDisabled && s.disabled }
        ${ className } pointer
        
      `}
      
    >

      { name }

    </button>

  )

}

export default Button;