import { useTranslation } from 'react-i18next'
import s from './ban.module.scss'

const BanPage = () => {

  const { t } = useTranslation()

  return (

    <div className = { s.content }>

      <img src="/ban.jpg" alt="ban" />

      <h2 className = 'h2'>
        
        { t('errors.banTitle') }
        
      </h2>

    </div>

  )

}


export default BanPage