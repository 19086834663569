import React, { useEffect, useState } from 'react';
import Carcas from '../../components/containers/carcas';
import Offer from '../../components/cards/offer';
import s from './offers.module.scss';
import { useQuery } from '@tanstack/react-query';
import { offerApi } from '../../../api/offer';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userApi } from '../../../api/user';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

/*Предложения продавцов*/

const addCount = 2

const Offers = () => {

  const [ searchParams ] = useSearchParams()

  const offersParams = {}


  if (searchParams.get('from')) offersParams.currency_from = searchParams.get('from')
  if (searchParams.get('to')) offersParams.currency_to = searchParams.get('to')
  if (searchParams.get('from_pay_type')) offersParams.payment_type_from = searchParams.get('from_pay_type')
  if (searchParams.get('to_pay_type')) offersParams.payment_type_to = searchParams.get('to_pay_type')
  if (searchParams.get('min_amount_sale')) offersParams.min_amount_sale = searchParams.get('min_amount_sale')
  if (searchParams.get('min_amount_buy')) offersParams.min_amount_buy = searchParams.get('min_amount_buy')

  const [count, setCount] = useState(addCount)

  const { data: offersList, isFetching, refetch } = useQuery({

    queryKey: ['offersList'],
    queryFn: () => offerApi.getOffersList({ ...offersParams }, count),

  })


  useEffect(() => {

    if ( count === addCount ) return
    refetch()

  }, [ count ])


  const loadMore = () => {

    setCount(prev => prev + addCount)

  }

  const { t, i18n } = useTranslation()

  return (

    <Carcas withBack lightGrayTheme noMenu>

      <p className={`${s.title} text-32-m`}>{t('offers.title')}</p>

      <section className = { s.container }>

        { !isFetching && offersList

          ?<OffersList count = { count } loadMore={loadMore} offersList={offersList} />
          :<Skeleton highlightColor = "#AA4CE0" baseColor="#CB91ED" height = { 120 } count = { 5 } />

         }

      </section>

    </Carcas>

  )

}

const OffersList = ({ count,offersList, loadMore }) => {

  const { t } = useTranslation()

  const { data: activeUserData, isFetching } = useQuery({

    queryKey: ['active_user'],
    queryFn: () => userApi.getActiveUser()

  })

  return (

    <>

      {offersList.data.list.length > 0 ? offersList.data.list.map((el, key) => (

        <Offer

          key={key}
          id={el.id}
          name={el.user.name}
          el={el}

          positive_reviews={el.review_status.positive}
          neutral_reviews={el.review_status.neutral}
          negative_reviews={el.review_status.negative}

          sending_currency_value={el.value_from}
          sending_currency_short={el.currency_from}
          sending_currency={''}
          sending_currency_type={el.payment_types.payment_type_from.name}

          getting_currency_value={el.value_to}
          getting_currency_short={el.currency_to}
          getting_currency={''}
          getting_currency_type={el.payment_types.payment_type_to.name}
          isDisabled = { el.user.id === activeUserData?.data?.id }

        />

        

      )) : <div className = { s.nothing } > <span>🤷‍♂️</span> <span>{ t('ui.not_found') }</span> </div> }

      { offersList.data.quantity > count && offersList.data.quantity !== offersList.data.list.length  && <button className={s.more_button} onClick={loadMore}>Загрузить еще</button>}

    </>
    
  )

}

export default Offers;