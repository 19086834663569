import axios  from "axios";


export const axiosInstance = axios.create({

  baseURL: 'https://api.twix.im/api',
  timeout: 1000000,

});

axiosInstance.interceptors.request.use(

  function ( config ) {

    const token = window.localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${ token }`

    return config

  },

  function (error) {

    return Promise.reject(error)

  }

)

axiosInstance.interceptors.response.use( response => response, error => {


  if (!error.response) {

    console.error('Сервер не отвечает', error.message);

  } else if (!error.response.data) {

    console.error('Нет данных в ответе', error.response); 

  } else {
  
    console.log(error);

  }

  return Promise.reject(error); // Передаем ошибку обратно

});


