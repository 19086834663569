import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Carcas from '../../components/containers/carcas';
import Textfield from '../../components/ui/textfield';
import s from './order.module.scss';
import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';

/*Создание заявки*/

const Order = ({ id = 1, action, activeItem,isPending, requisites }) => {

  const { t } = useTranslation()

  const [orderId, setOrderId] = useState(id);
  const [nameValue, setNameValue] = useState("");
  const [walletValue, setWalletValue] = useState("");

  const location = useLocation();

  useEffect(() => {

    const urlParams = new URLSearchParams(location.search);
    const idParam = urlParams.get("id");

    if (idParam) { setOrderId(Number(idParam)) }

  }, [location.search]);

  return (

    <Carcas grayTheme withBack>

      <main className={`${s.content}`}>

        <p className={`${s.title} text-32-m`}>{t('order.makeOrder')}</p>


        {requisites.identifier &&

          <Textfield

            set={setNameValue}
            value={nameValue}
            className={s.textfield}
            placeholder={requisites.identifier}

          />

        }

        {requisites.requisites && <Textfield

          set={setWalletValue}
          value={walletValue}
          className={s.textfield}
          placeholder={requisites.requisites}

        />

        }

        <Button

          action={() => action({ requisites: walletValue, identifier: nameValue })}
          className={s.button}
          name={t('ui.next')}
          isDisabled={
            ((requisites.requisites && walletValue.length < 2) || (nameValue.length < 2 && requisites.identifier))
            || isPending
          }

        />

      </main>

    </Carcas>

  )

}

export default Order;