import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../button';
import s from '../button/button.module.scss'

/*кнопка-ссылка*/

const ButtonLink = ({
  
  name = "ок",
  className,
  small = false,
  light = false,
  url = "",
  disabled = false,

}) => {

  return (

    <Link className={`${disabled && s.disabled}`} to={url}>

      <Button

        name = { name }
        className = { className }
        small = { small }
        light = { light }

      />

    </Link>

  );

};

export default ButtonLink;