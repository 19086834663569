import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';

import Offers from './offers';
import Bid from './bid';
import Order from './order';
import Rate from './rate';
import Chat from './chat';
import NotFound from './404';
import Home from './home';
import BanPage from './ban';
import { useQuery } from '@tanstack/react-query';
import { userApi } from '../../api/user';
import { useEffect, useState } from 'react';

const AppRouter = () => {

  const navigate = useNavigate()

  const { data: activeUserData } = useQuery({

    queryKey: ['active_user'],
    queryFn: () => userApi.getActiveUser()

  })

  const isBanned = activeUserData?.data?.banned_at

  const [ redirectedOnChat, setRedirectedOnChat ] = useState( false )
  const searchParams  = useSearchParams()

  const chatParam = searchParams[0].get('tgWebAppStartParam')

  useEffect(() => {
    
    if ( isBanned ) navigate('/ban')

    if ( !redirectedOnChat && activeUserData && chatParam && chatParam?.includes( 'chat' )) {

      const chatId = chatParam.split('_')[1]
      setRedirectedOnChat( true )
      navigate(`chat?id=${ chatId }`)
      
    }

  }, [ activeUserData ])

  return (
 
    <Routes>

      <Route path = "/offers" element = { <Offers /> } />
      {/* <Route path = "/signIn" element = { <Sign params = "in" /> } /> */}
      <Route path = "/" element = { <Home /> } />
      {/* <Route path = "/signUp" element = { <Sign params = "up" /> } /> */}
      <Route path = "/bid" element = { <Bid /> } />
      <Route path = "/ban" element = { <BanPage /> } />
      <Route path = "/order" element = { <Order /> } />
      <Route path = "/rate" element = { <Rate /> } />
      <Route path = "/chat" element = { <Chat /> } />      
      <Route path = "*" element = { <NotFound /> } />
      <Route path = "notFound" element = { <NotFound onMain /> } />

    </Routes>


  )

}

export default AppRouter;