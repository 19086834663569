import { axiosInstance } from "../instance"

export const ratesApi = {

  async getCurrencies () {

    return (await axiosInstance.post('/v1/rate/list')).data

  },
  
  async getPayTypes ( currenciType ) {

    return (await axiosInstance.get('/v1/payment_type/list', {params: { type: currenciType  }})).data

  }

}