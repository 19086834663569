import CryptoJS from "crypto-js";

export function decodeText ( text, cutTags ) {

  const words = CryptoJS.enc.Hex.parse( text )
  const wordsEnc = words.toString( CryptoJS.enc.Utf8 )
  
  if ( !wordsEnc ) {
    
    return ''
    
  }
  
  const messageEnc = wordsEnc.split( '||' )
  
  let message = CryptoJS.AES.decrypt( messageEnc[ 0 ], messageEnc [ 1 ])
  
  message = message.toString( CryptoJS.enc.Utf8 )

  if ( cutTags ) {

    message = message.replace(/<\/?(\s|\S)*?>/g, ' ').replace(/(&nbsp;|\s| ){2,}/g, ' ').trim()

  }


  return message

}


