import React, { Fragment, useState } from 'react';
import { ReactComponent as ArrowDouble } from '../../icons/DoubleArrow.svg';

import s from './deals.desk.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userApi } from '../../../../api/user';
import { useMutation, useQuery } from '@tanstack/react-query';
import { chatApi } from '../../../../api/chat';
import { Store } from 'react-notifications-component';


const offersStatuses = {
  new: "Новый",
  inprocess: "В процессе",
  dispute: "Открыт спор",
  "dispute-approved": "Спор одобрен",
  'done': "Закончен",
  'canceled': "Отменен",
  'dispute-canceled': "Спор отменен",
}

const filters = {

  active: [
    'new',
    'inprocess',
    'dispute',
    'dispute-approved',
  ],

  noActive: [
    'done',
    'canceled',
    'dispute-canceled'
  ]

}

const DealsDesk = () => {

  const { t, i18n } = useTranslation()

  const [currentFilters, setCurrentFilters] = useState('active')
  const navigate = useNavigate()

  const toggleFilters = () => {

    setCurrentFilters(prev => prev === 'active' ? 'noActive' : 'active' )

  }

  const checkChatDataMutation = useMutation({

    mutationFn: (chat_id) => chatApi.getChatInfo({ chat_id }),
    onSuccess: (res, chat_id) => {

      if (res.status && chat_id) return navigate(`/chat?id=${chat_id}`)

      Store.addNotification({

        title: "Err",
        message: res.message + ' ' + res.code,
        type: "danger",
        insert: "top",
        container: "top-full",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }

      });

    }

  })

  const clickHandler = (chat_id) => { checkChatDataMutation.mutate( chat_id ) }

  const { data: activeUserData, isSuccess: userDataLoaded } = useQuery({

    queryKey: ['active_user'],
    queryFn: () => userApi.getActiveUser()

  })

  const { data: dealsHistory, refetch: refetchHistory } = useQuery({

    queryKey: ['deals', currentFilters],
    queryFn: () => userApi.getDealHistory({

      filter_data: {status: filters[ currentFilters ] },
      hash: true,
      limit: 50,
      page: 1,
      start: 0,

    }),

  })

  return (

    <Fragment>

      <div className = {`${ s.head } flex items-center between`}>

        <p className={`${s.head__title} text-16-m`}>{t('exchanger.myDeals')}</p>

        <div
        
          className="flex items-center pointer"
          
        >

          <p
          
            onClick={() => toggleFilters()}
            className = {`${ s.head__filter } text-16-m`}
            
          >
            
            {currentFilters === 'active' ? t('exchanger.active') : t('exchanger.noActive')}
            
          </p>

          <ArrowDouble className = {`${ s.head__arrows }`}/>

        </div>

      </div>
      
      <div className = {`${ s.deals }`}>

        {activeUserData && dealsHistory?.data?.list?.length && dealsHistory.data.list.map((el, key) => {

          const we = activeUserData.data.id === el.buyer.id ? el.buyer : el.seller
          const myRole = we.id === el.buyer.id ? 'buyer' : 'seller'
          const companion = activeUserData.data.id !== el.buyer.id ? el.buyer : el.seller

          return (

            <button onClick={() => clickHandler(el.chat_id)} key={key}>

              <div className={`${s.deals__item} flex`}>

                <div className={`${s.deals__titles}`}>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__titles__title} text-14-m`}>{t('exchanger.card.transactionID')}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__titles__title} text-14-m`}>{t('exchanger.card.date')}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__titles__title} text-14-m`}>{t('exchanger.card.duration')}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__titles__title} text-14-m`}>{t('exchanger.card.typeTransaction')}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__titles__title} ${s.deals__titles__title__dual} text-14-m`}>{t('exchanger.card.whoWasSheWith')}</p>

                  </div>

                  <div className={s.deals__textbox} />

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__titles__title} text-14-m`}>{t('exchanger.card.directionAndAmount')}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__titles__title} text-14-m`}>{t('exchanger.card.status')}</p>

                  </div>

                </div>

                <div className={`${s.deals__data}`}>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__data__text} ${s.deals__data__text__purple} text-12-m`}>#{el.id}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__data__text} text-12-m`}>{el.created_at.split(' ')[0].split('-').join('.')}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__data__text} text-12-m`}>{el.duration ? el.duration : "-"}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__data__text} text-12-m`}>{myRole === 'buyer' ? t('ui.buy') : t( 'ui.sale' )}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__data__text} ${s.deals__data__text__purple} text-12-m`}>{companion.name}</p>

                  </div>

                  <div className={`${s.deals__textbox} flex`}>

                    <p className={`${s.deals__data__text} text-12-m`}>{el.value_from}</p>
                    <p className={`${s.deals__data__text} text-12-m`}>&nbsp;{el.offer.currency_from}&nbsp;</p>
                    <p className={`${s.deals__data__text} ${s.deals__data__text__gray} text-12-m`}>&nbsp;&nbsp;{el.currency_name}&nbsp;→</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__data__text} text-12-m`}>{el.value_to}</p>

                  </div>

                  <div className={s.deals__textbox}>

                    <p className={`${s.deals__data__text} text-12-m`}>{ offersStatuses[el.status]}</p>

                  </div>

                </div>

              </div>

            </button>
          )

        })}

      </div>

    </Fragment>

  )

}

export default DealsDesk;