import React from 'react';
import s from './not.found.module.scss';
import Button from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/*404 page*/

const NotFound = ({ onMain }) => {

  const navigate = useNavigate()
  const { t } = useTranslation()

  function back() {

    if ( onMain ) return navigate('/')

    window.history.back()
    
  }

  return (

    <main className = {`background ${ s.content }`}>

      <p className = {`${ s.title }`}>{ t('ui.err_404') }</p>

      <p className = {`${ s.subtitle } text-16-m`}>{ t('ui.err_404_desc') }</p>

      <Button 
      
        small
        light
        name = { t('ui.back') }
        action = { () => navigate('/') }
      
      />

    </main>

  )

}

export default NotFound;