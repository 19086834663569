import { useTranslation } from 'react-i18next';
import s from '../chat.module.scss'
import moment from 'moment';


export const SystemMessage = ({ el, messageIndex, timeDesc, user }) => {

  const { t, i18n } = useTranslation()

  return (

    <p className={`${s.bot__text} text-14-m`}>

      <div className={` mt-m-15`}>

        <span className={`${s.chat_time_black}`}>{timeDesc ? `${  user?.user?.name ?  user?.user?.name + ': ' : '' } ${ t(timeDesc)  }` + ': ' : ''}</span>
        <span className={`${s.chat_time}`}>{formatTime(el.created)}</span>
      
      </div>

      <p>

        {t(messageIndex)}

      </p>

    </p>

  )

}

export const MESSAGE = ({ content, type, file, el, isModer }) => {

  return (

    <div className={`${s.messages} ${type === 0 ? s.messages__in : type === 1 && s.messages__out} flex column`}>

      <p className={`${s.messages__message} ${type === 0 ? s.messages__message__in : type === 1 && s.messages__message__out} text-14-m`}>
        {<div dangerouslySetInnerHTML={{ __html: get_html_code_with_a(content) }} />}
      </p>

      {file && <img className={s.message_file} src={file.path_origin} alt={file.name} />}

      <div className={`flex flex-start ${isModer && s.moder_item}`}>
        
        <span className={`${s.message_time} ${type === 0 && s.from}`}>{formatTime(el.created)}</span>
        {isModer && <span className={`${s.message_time}  ${type === 0 && s.from}`}>Moderator</span>}
        
      </div>

    </div>

  );

};


export const buyerMessages = {

  "chat.press_paid_button.title": (el) => <SystemMessage el={el} messageIndex={'chat.pressPaidButtonTitle'} />,
  "chat.deal_buyer_end.title": (el) => <SystemMessage el={el} timeDesc={"chat.dealBuyerEndTimeTitle"} />,

}

export const sellerMessages = {

  "chat.deal_buyer_end.title": (el) => <SystemMessage el={el} messageIndex={"chat.sellerBuyerEndMessage"} timeDesc={"chat.dealBuyerEndTimeTitle"}></SystemMessage>,

}

export const commonMessages = {

  "chat.dispute_open.title": (el, user) => <SystemMessage el={el} user = { user } messageIndex={'chat.userStartDisputDesc'} timeDesc={`chat.userStartDisputTimeTitle`}></SystemMessage>,
  "chat.dispute_close.title": (el, user) => <SystemMessage el={el} timeDesc={`chat.disputeClosed`}></SystemMessage>,
  "chat.deal_end.title": (el, user) => <SystemMessage el={el} timeDesc={`chat.dealEnd`}></SystemMessage>,
  "chat.deal_seller_end.title": (el, user) => <SystemMessage el={el} timeDesc={`chat.sellerDealEnd`}></SystemMessage>,
  "chat.deal_buyer_end.title": (el, user) => <SystemMessage el={el} messageIndex={'chat.buyerDealEndDesc'} timeDesc={`chat.buyerDealEnd`}></SystemMessage>,

}

export const getMessagesForBuyer = (path, el) => {

  if (path === 'chat.created.title') return ''

  return buyerMessages[path] ? buyerMessages[path](el) : ''

}

export const getMessagesForSeller = (path, el) => {

  if (path === 'chat.created.title') return ''

  return sellerMessages[path] ? sellerMessages[path](el) : ''

}

export const getCommonMessages = (path, el, user) => {

  if (path === 'chat.created.title') return ''

  return commonMessages[path] ? commonMessages[path](el, user) : ''

}

export function getDateString(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));
  if (diffDays === 0) {
    return 'Сегодня';
  } else if (diffDays === 1) {
    return 'Вчера';
  } else if (diffDays === 2) {
    return 'Позавчера';
  }
  return '';
}

export function formatTime(time) {

  return moment.parseZone(time).local().format('HH:mm')

}

export function get_html_code_with_a(text) {

  const regExp = /(https?:\/\/[\w\-.,@?^=%&:/~\+#]*)/;
  const matches = text.match(regExp);
  let newText = text

  if (matches) {
    newText = text.replace(matches[0], `<a target="_blank" href=${matches[0]} class=${s.link}>${matches[0]}</a>`)
  }

  return newText

}