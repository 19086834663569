
import { ReactComponent as Self } from '@/react/components/icons/offer.self.svg';
import { ReactComponent as Hand } from '@/react/components/icons/Hand.svg';
import { ReactComponent as Group } from '@/react/components/icons/offer.group.svg';
import { ReactComponent as Card } from '@/react/components/icons/offer.card.svg';
import { ReactComponent as On } from '@/react/components/icons/offer.on.svg';
import { ReactComponent as Of } from '@/react/components/icons/offer.of.svg';


import s from './tags.list.module.scss'
import { useTranslation } from 'react-i18next';

const TagList = ({ el }) => {

  const { t } = useTranslation()


  if ( !el ) return null

  return (

    <div className="flex items-end between">

      { el.is_auto_mode &&

        <div

          data-desk={ t('tagList.autoMode') }
          className={`${s.link_icon} ${s.icon_bg}`}>

          <Hand />

        </div>

      }

      { el.is_verify_document &&

        <div 

          data-desk={ t('tagList.verifyDocument') } 
          className={s.link_icon}
        
        >

          <Self />

        </div>

      }

      { el.is_legal_entity &&

        <div 
          
          data-desk={ t('tagList.legalEntity') } 
          className={s.link_icon}

        >

          <Group />

        </div>

      }

      { el.is_verify_card &&

        <div 
          
          data-desk={ t('tagList.verifyCard') } 
          className={s.link_icon}
        
        >

          <Card />

        </div>

      }

      { el.is_payout_system &&

        <div

          data-desk={ t('tagList.payoutSystem') }
          className={ s.link_icon }>

          <On />

        </div>

      }

      { el.is_acceptance_system &&

        <div

          data-desk={ t('tagList.acceptanceSystem') }
          className={s.link_icon}

        >

          <Of />

        </div>

      }

    </div>

  )

}

export default TagList